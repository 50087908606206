import React from 'react';

const CommonCheckBox = ({ setLoginObj, loginObj, setSignUpObj, signUpObj, isSignUp }: any) => {
  const handleCheckboxChange = (event: any) => {
    const checked = event.target.checked;

    if (isSignUp && setSignUpObj) {
      setSignUpObj((prev: any) => ({
        ...prev,
        send_whatsapp: checked,
      }));
    } else if (!isSignUp && setLoginObj) {
      setLoginObj((prev: any) => ({
        ...prev,
        send_whatsapp: checked,
      }));
    }
  };
  return (
    <div className="flex flex-row justify-center">
      <input
        type="checkbox"
        checked={isSignUp ? signUpObj?.send_whatsapp : loginObj?.send_whatsapp}
        onChange={handleCheckboxChange}
      ></input>
      <p className="ml-1 text-xs text-blue-800">Send OTP on Whatsapp</p>
    </div>
  );
};

export default CommonCheckBox;
