import React from 'react';
import PhoneInput from 'react-phone-input-2';

import CommonCheckBox from './commonCheeckBox/CommonCheckBox';
import FloatingTextInput from './CommonTextInput';

const SignUpCard = ({
  handleInputChange,
  signUpObj,
  error,
  handleSignUp,
  handleLoginClick,
  signupError,
  setLoginObj,
  loginObj,
  setSignUpObj,
}: any) => {
  return (
    <div data-testid="mock-signup-card" className="px-4 sm:px-6 md:px-8">
      <div className="mb-4">
        <FloatingTextInput
          label="Name"
          value={signUpObj.name || ''}
          onChangeTextInput={(value) => handleInputChange({ target: { name: 'name', value } })}
          placeholder="Enter your name"
          keyboardType="name"
          titleActiveColor="black"
          titleInactiveColor="gray"
        />
        {error.name && <p className="flex justify-center text-sm text-red-500">{error.name}</p>}
      </div>
      <div className="mb-4">
        <FloatingTextInput
          label="Email"
          value={signUpObj.email || ''}
          onChangeTextInput={(value) => handleInputChange({ target: { name: 'email', value } })}
          placeholder="Enter your email"
          keyboardType="email"
          titleActiveColor="black"
          titleInactiveColor="gray"
        />
        {error.email && <p className="flex justify-center text-sm text-red-500">{error.email}</p>}
      </div>

      <div className="mb-4">
        <label className="mb-2 flex justify-start font-medium">{'Phone Number'}</label>
        <div className="w-full items-center rounded-lg border px-2 py-1 focus:border-green-400 focus:outline-none focus:ring-2 focus:ring-green-400">
          <PhoneInput
            country={'in'}
            value={signUpObj.phone_number || ''}
            onChange={(value) => handleInputChange({ target: { name: 'phone_number', value: `+${value}` } })}
            disabled={false}
            inputProps={{
              style: { border: 'red', fontSize: '14px', width: '100%' },
              placeholder: 'Enter number',
              'data-testid': 'signup-phone-input',
            }}
            buttonStyle={{
              backgroundColor: 'transparent',
              border: 'green',
            }}
          />
        </div>

        {error.phone_number && <p className="mt-1 flex justify-center text-sm text-red-500">{error.phone_number}</p>}
      </div>
      <CommonCheckBox
        setLoginObj={setLoginObj}
        loginObj={loginObj}
        setSignUpObj={setSignUpObj}
        signUpObj={signUpObj}
        isSignUp={true}
      />
      <div>{signupError && <p className="mt-1 flex justify-center text-sm text-red-500">{signupError}</p>}</div>
      <button
        data-testid="sign_up_button"
        className="mt-3 w-full rounded-full bg-primary-green py-2 font-medium text-white hover:bg-green-600"
        onClick={handleSignUp}
      >
        Sign Up
      </button>

      <p className="mt-12 text-center text-gray-600">
        Already have an account?{' '}
        <a href="#" className="text-blue-500" onClick={handleLoginClick}>
          Log In
        </a>
      </p>
    </div>
  );
};

export default SignUpCard;
