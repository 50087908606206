export const API_ENDPOINTS = {
  TOKEN_REFRESH: '/token/refresh/',
  REGISTER: 'auth/register/',
  SEND_OTP: 'otp/create-otp/',
  VALIDATE_OTP: 'otp/validate-otp/',
  RESEND_OTP: 'otp/resend-otp/',
  LOGOUT: 'auth/logout/',
  USER_PROFILE: 'user-profile/user_profile_data/',
  FACULTY_DETAILS: 'faculty/',
  PLANS: 'plan/',
  PLANS_DETAILS: 'plan/plan_details/?',
  VALIDATE_COUPON: 'coupon/valid_coupon/',
  INITIATE_ORDER: 'order/initiate_order/',
  CREATE_ORDER: 'order/create_order/',
};
