'use client';

import React from 'react';
import Image from 'next/image';
import medwayLogo from '@/assets/Logo/medway-logo.png';

import LoginButton from './LoginButton';
import OtpScreen from './OtpScreen';
import SignUpCard from './SignUpCard';
import useLoginCardHook from './useLoginCardHook';

interface LoginCardProps {
  onClose: () => void;
}

const LoginCard: React.FC<LoginCardProps> = ({ onClose }) => {
  const {
    isOTP,
    handleBackButton,
    handleCloseButton,
    isSignUp,
    loginObj,
    signUpObj,
    otp,
    onChangeHandlerRefactor,
    otpError,
    handleVerifyOTP,
    isTimerActive,
    formatTimer,
    resendOtp,
    timer,
    handleInputChange,
    error,
    handleSignUp,
    handleLoginClick,
    signupError,
    loginUpError,
    handleArrowClick,
    handleSignUpClick,
    setLoginObj,
    setSignUpObj,
  } = useLoginCardHook({
    onClose,
  });

  const renderContent = () => {
    if (isOTP) {
      return (
        <OtpScreen
          isSignUp={isSignUp}
          loginObj={loginObj}
          signUpObj={signUpObj}
          otp={otp}
          onChangeHandlerRefactor={onChangeHandlerRefactor}
          otpError={otpError}
          handleVerifyOTP={handleVerifyOTP}
          isTimerActive={isTimerActive}
          formatTimer={formatTimer}
          resendOtp={resendOtp}
          timer={timer}
        />
      );
    }

    if (isSignUp) {
      return (
        <SignUpCard
          handleInputChange={handleInputChange}
          signUpObj={signUpObj}
          error={error}
          handleSignUp={handleSignUp}
          handleLoginClick={handleLoginClick}
          signupError={signupError}
          setSignUpObj={setSignUpObj}
        />
      );
    }

    return (
      <LoginButton
        handleInputChange={handleInputChange}
        loginObj={loginObj}
        setLoginObj={setLoginObj}
        error={error}
        loginUpError={loginUpError}
        handleArrowClick={handleArrowClick}
        handleSignUpClick={handleSignUpClick}
      />
    );
  };

  return (
    <div
      data-testid="modal"
      id="modal-overlay"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
    >
      <div className="relative w-full max-w-sm rounded-lg bg-white p-6 shadow-md">
        {isOTP && (
          <button
            data-testid="back-btn"
            className="absolute left-3 top-3 text-black hover:text-gray-500"
            onClick={handleBackButton}
          >
            ←
          </button>
        )}
        <button className="absolute right-3 top-3 text-black hover:text-gray-500" onClick={handleCloseButton}>
          ✕
        </button>

        <div className="mb-4 flex justify-center">
          <Image src={medwayLogo} alt="Medway Logo" width={80} height={60} />
        </div>

        <h2 data-testid="findText" className="mb-4 text-center text-2xl font-medium">
          {isSignUp ? 'Sign Up' : 'Login'}
        </h2>

        {renderContent()}
      </div>
    </div>
  );
};

export default LoginCard;
