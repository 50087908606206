import React from 'react';
import PhoneInput from 'react-phone-input-2';

import CommonCheckBox from './commonCheeckBox/CommonCheckBox';

const LoginButton = ({
  handleInputChange,
  loginObj,
  error,
  loginUpError,
  handleArrowClick,
  handleSignUpClick,
  setLoginObj,
  setSignUpObj,
  signUpObj,
}: any) => {
  return (
    <>
      <div data-testid="mock-login-card" className="mt-15 mb-3">
        <label className={`mb-2 flex justify-start font-medium`}>{'Phone Number'}</label>
        <div
          data-testid="phoneInput-testid"
          className="w-full items-center rounded-lg border px-2 py-1 focus:border-green-400 focus:outline-none focus:ring-2 focus:ring-green-400"
        >
          <PhoneInput
            country={'in'}
            value={loginObj.phone_number}
            onChange={(value) => handleInputChange({ target: { name: 'phone_number', value: `+${value}` } })}
            disabled={false}
            inputProps={{
              style: { border: 'red', fontSize: '14px', width: '100%' },
              placeholder: 'Enter Phone number',
              'data-testid': 'login-phone-input',
            }}
            buttonStyle={{
              backgroundColor: 'transparent',
              border: 'green',
            }}
          />
        </div>

        {error.phone_number && <p className="mt-1 flex justify-center text-sm text-red-500">{error.phone_number}</p>}
      </div>
      <CommonCheckBox
        setLoginObj={setLoginObj}
        loginObj={loginObj}
        setSignUpObj={setSignUpObj}
        signUpObj={signUpObj}
        isSignUp={false}
      />
      <div>{loginUpError && <p className="mt-1 flex justify-center text-sm text-red-500">{loginUpError}</p>}</div>
      <button
        className="mt-3 w-full rounded-full bg-primary-green py-2 font-medium text-white hover:bg-green-600"
        onClick={handleArrowClick}
      >
        Log In
      </button>

      <p className="mt-12 text-center text-gray-600">
        {"Don't have an account?"}{' '}
        <button data-testid="movetosignup" className="text-blue-500" onClick={handleSignUpClick}>
          Sign Up
        </button>
      </p>
    </>
  );
};

export default LoginButton;
