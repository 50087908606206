import { useRef, useState } from 'react';
import { useRouter } from 'next/navigation';
import { useAppDispatch } from '@/store/hooks';
import { reset } from '@/store/slices/authSlice';
import { resend_otp, sendOtp, UserRegisterApi, ValidateOtp } from '@/store/thunks/authThunks';
import { UserProfile } from '@/store/thunks/userProfileThunks';
import { useSelector } from 'react-redux';

interface LoginData {
  phone_number: string;
  device_id: string;
  device_name: string;
  send_whatsapp: boolean;
}

interface SignUpData {
  name: string;
  email: string;
  phone_number: string;
  device_id: string;
  device_name: string;
  send_whatsapp: boolean;
}

const useLoginCardHook = ({ onClose }: any) => {
  const router = useRouter();
  const [isSignUp, setIsSignUp] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [timer, setTimer] = useState(10);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const { signupError, loginUpError, otpError } = useSelector((state: any) => state.auth);
  const [error, setError] = useState<any>({
    name: '',
    email: '',
    phone_number: '',
  });
  const [loginObj, setLoginObj] = useState<LoginData>({
    phone_number: '',
    device_id: '21A79C24-5C54-4E9C-A6FC-7060C307A48C',
    device_name: 'iPhone 15',
    send_whatsapp: false,
  });

  const [signUpObj, setSignUpObj] = useState<SignUpData>({
    name: '',
    email: '',
    phone_number: '',
    device_id: '21A79C24-5C54-4E9C-A6FC-7060C307A48C',
    device_name: 'iPhone 15',
    send_whatsapp: false,
  });

  const ref = useRef<any[]>([]);
  const [otp, setOtp] = useState(new Array(6).fill(''));

  const dispatch = useAppDispatch();

  const startTimer = () => {
    setTimer(10);
    setIsTimerActive(true);
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          setIsTimerActive(false);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const handleSignUpClick = () => {
    setLoginObj((prev) => ({
      ...prev,
      phone_number: '',
    }));
    setIsSignUp(true);
    setError('');
    dispatch(reset({}));
  };

  const handleLoginClick = () => {
    setSignUpObj((prev) => ({
      ...prev,
      name: '',
      email: '',
      phone_number: '',
    }));
    setIsSignUp(false);
    setError('');
    dispatch(reset({}));
  };

  const handleArrowClick = async () => {
    let isValid = true;
    let errors = {
      phone_number: '',
    };
    if (!loginObj.phone_number || !phoneRegex.test(loginObj.phone_number)) {
      errors.phone_number = 'Please enter a valid phone number';
      isValid = false;
    }
    setError(errors);
    dispatch(reset({}));

    if (isValid) {
      await dispatch(sendOtp({ ...loginObj, phone_number: `${loginObj.phone_number}` })).unwrap();
      setIsOTP(true);
      startTimer();
    }
  };

  const nameRegex = /^[A-Za-z\s]+$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^\+?\d+$/;

  const handleSignUp = async () => {
    let isValid = true;
    let errors = {
      name: '',
      email: '',
      phone_number: '',
    };

    if (!signUpObj.name || !nameRegex.test(signUpObj.name)) {
      errors.name = 'Please enter a valid name';
      isValid = false;
    }

    if (!signUpObj.email || !emailRegex.test(signUpObj.email)) {
      errors.email = 'Please enter a valid email';
      isValid = false;
    }

    if (!signUpObj.phone_number || !phoneRegex.test(signUpObj.phone_number)) {
      errors.phone_number = 'Please enter a valid phone number';
      isValid = false;
    }
    setError(errors);
    dispatch(reset({}));

    if (isValid) {
      await dispatch(UserRegisterApi({ ...signUpObj, phone_number: `${signUpObj.phone_number}` })).unwrap();
      setIsOTP(true);
      startTimer();
    }
  };

  const handleInputChange = (e: any) => {
    dispatch(reset({}));
    setError('');
    const { name, value } = e.target;
    if (isSignUp) {
      setSignUpObj({ ...signUpObj, [name]: `${value}` });
    } else {
      setLoginObj({ ...loginObj, [name]: `${value}` });
    }
  };

  const handleVerifyOTP = async () => {
    const formattedOtp = otp.join('');
    let response;
    if (isSignUp) {
      response = await dispatch(
        ValidateOtp({ ...signUpObj, phone_number: `${signUpObj.phone_number}`, otp: formattedOtp }),
      );
    } else {
      response = await dispatch(
        ValidateOtp({ ...loginObj, phone_number: `${loginObj.phone_number}`, otp: formattedOtp }),
      );
    }

    if (response.payload?.token) {
      onClose();
      router.push('/');
      await dispatch(UserProfile());
    } else if (response.payload?.error) {
      setError(response.payload?.error);
    }
  };

  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  const resendOtp = () => {
    startTimer();
    dispatch(
      resend_otp({
        phone_number: isSignUp ? signUpObj.phone_number : loginObj.phone_number,
        send_sms: true,
        send_whatsapp: false,
      }),
    );
  };

  const onChangeHandlerRefactor = (otpCopy: any) => {
    dispatch(reset({}));
    setOtp(otpCopy);
  };

  const handleBackButton = () => {
    setIsOTP(false);
    dispatch(reset({}));
    setOtp(new Array(6).fill(''));
  };

  const handleCloseButton = () => {
    dispatch(reset({}));
    onClose();
  };
  return {
    isOTP,
    handleBackButton,
    handleCloseButton,
    isSignUp,
    loginObj,
    signUpObj,
    otp,
    onChangeHandlerRefactor,
    ref,
    otpError,
    handleVerifyOTP,
    isTimerActive,
    formatTimer,
    resendOtp,
    timer,
    handleInputChange,
    error,
    handleSignUp,
    handleLoginClick,
    signupError,
    loginUpError,
    handleArrowClick,
    handleSignUpClick,
    setLoginObj,
    setSignUpObj,
  };
};

export default useLoginCardHook;
