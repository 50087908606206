import React, { useRef } from 'react';

const OtpScreen = ({
  isSignUp,
  loginObj,
  signUpObj,
  otp,
  onChangeHandlerRefactor,
  otpError,
  handleVerifyOTP,
  isTimerActive,
  formatTimer,
  resendOtp,
}: any) => {
  const ref = useRef<any[]>([]);
  const onChangeHandler = (e: any, index: any) => {
    const { value } = e.target || {};
    const otpCopy = [...otp];
    otpCopy[index] = value;
    onChangeHandlerRefactor(otpCopy);
    if (value) {
      ref.current[index + 1]?.focus();
    }
  };

  const backHandler = (e: any, index: any) => {
    if (e.key === 'Backspace' && !otp[index]) {
      ref.current[index - 1]?.focus();
    }
  };

  return (
    <div data-testid="otp-screen">
      <h2 data-testid="OTP" className="mb-4 mt-10 text-center text-2xl font-bold">
        OTP Verification
      </h2>
      <div className="mb-6 text-center">
        <p className="text-gray-700">
          Enter the code from the sms we sent{' '}
          <span className="text-black">to {!isSignUp ? loginObj.phone_number : signUpObj.phone_number}</span>
        </p>
      </div>

      <div className="mb-4 flex justify-between">
        {otp.map((data: any, index: any) => (
          <input
            ref={(e: any) => {
              if (e) ref.current[index] = e;
            }}
            onChange={(e) => onChangeHandler(e, index)}
            onKeyDown={(e) => backHandler(e, index)}
            key={index}
            type="text"
            maxLength={1}
            value={data}
            className="size-12 rounded-lg border border-green-500 text-center focus:outline-none focus:ring-2 focus:ring-green-400"
          />
        ))}
      </div>
      <div> {otpError && <p className="mt-1 flex justify-center text-sm text-red-500">{otpError}</p>}</div>
      <button
        className="mt-3 w-full rounded-full bg-primary-green py-2 font-medium text-white hover:bg-green-600"
        onClick={handleVerifyOTP}
      >
        Verify OTP
      </button>

      <div className="mt-3 flex justify-center text-center text-gray-600">
        <p>{"I didn't receive any code."}</p>
        {isTimerActive ? (
          <p data-testid="timer">{formatTimer()}</p>
        ) : (
          <button onClick={resendOtp} className="cursor-pointer text-blue-500">
            Resend OTP
          </button>
        )}
      </div>
    </div>
  );
};

export default OtpScreen;
