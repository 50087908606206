import { API_ENDPOINTS } from '@/services/apiEndpoints';
import { getMedway } from '@/services/medway_api_services';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const UserProfile = createAsyncThunk<any, void>('User/UserProfile', async (_, thunkAPI) => {
  try {
    const response = await getMedway(API_ENDPOINTS.USER_PROFILE);
    if (!response) {
      return null;
    }
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message || 'something went wrong in user profile');
  }
});
