import { API_ENDPOINTS } from '@/services/apiEndpoints';
import { postMedway } from '@/services/medway_api_services';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const UserRegisterApi = createAsyncThunk<any, any>('user/UserAuth', async (params: any, thunkApi) => {
  try {
    const endPoint = `${API_ENDPOINTS.REGISTER}`;
    const response = await postMedway(endPoint, params, false);
    if (!response) {
      return null;
    }
    const data = response.data;
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(`${error}`);
  }
});

export const ValidateOtp = createAsyncThunk<any, any>('auth/login', async (loginObj, thunkApi) => {
  try {
    const response = await postMedway(API_ENDPOINTS.VALIDATE_OTP, loginObj, false);
    if (!response) {
      return null;
    }
    localStorage.setItem('token', response.token);
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message || 'Login failed');
  }
});
export const sendOtp = createAsyncThunk<any, any>('auth/sendOtp', async (loginObj, thunkApi) => {
  try {
    const response = await postMedway(API_ENDPOINTS.SEND_OTP, loginObj, false);
    if (!response) {
      return null;
    }
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message || 'OTP not send');
  }
});

export const logOut = createAsyncThunk<any, void>('auth/logout', async (_, thunkApi) => {
  try {
    const response = await postMedway(API_ENDPOINTS.LOGOUT, {});
    if (!response) {
      return null;
    }
    localStorage.removeItem('token');
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message || 'LogOut failed');
  }
});

export const resend_otp = createAsyncThunk<any, any>('auth/resend_otp', async (resendOtp, thunkApi) => {
  try {
    const response = await postMedway(API_ENDPOINTS.RESEND_OTP, resendOtp);
    if (!response) {
      return null;
    }
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message || 'LogOut failed');
  }
});
