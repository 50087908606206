import { logout } from '@/store/slices/authSlice';
import axios from 'axios';

import { API_ENDPOINTS } from './apiEndpoints';
import { PROD_BASE_URL, STATIC_TOKEN } from './config';

const BASE_URL = PROD_BASE_URL;
if (!BASE_URL) {
  throw new Error('BASE_URL is not defined. Check your configuration.');
}
const isMobile = typeof navigator !== 'undefined' && /mobile|android|iphone|ipad/i.test(navigator.userAgent);
const CLIENT_TYPE = isMobile ? 'mobile' : 'web';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Client-Type': CLIENT_TYPE,
  },
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (
      config.url === API_ENDPOINTS.SEND_OTP ||
      config.url === API_ENDPOINTS.VALIDATE_OTP ||
      config.url === API_ENDPOINTS.REGISTER
    ) {
      return config;
    }
    if (!token) {
      config.headers['Authorization'] = `Bearer ${STATIC_TOKEN}`;
    } else {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error instanceof Error ? error : new Error(String(error)));
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response?.status, 'error.response?.status');

    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      import('@/store/store').then(({ store }) => {
        store.dispatch(logout());
      });
    }
    return Promise.reject(error instanceof Error ? error : new Error(String(error)));
  },
);
export const getMedway = async (url: string, requestParams?: object, includeAuth: boolean = true) => {
  try {
    axiosInstance.defaults.headers['device-id'] = '21A79C24-5C54-4E9C-A6FC-7060C307A48C';
    const headers: any = {
      'Content-Type': 'application/json',
      'X-Client-Type': CLIENT_TYPE,
    };
    if (includeAuth) {
      let token = localStorage.getItem('token') ?? STATIC_TOKEN;
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
    }
    const response = await axiosInstance.get(url, { params: requestParams, headers });
    return response?.data ?? response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error fetching data');
  }
};

export const postMedway = async (url: string, requestParams: object, includeAuth: boolean = true) => {
  try {
    const headers: any = {
      'Content-Type': 'application/json',
      'X-Client-Type': CLIENT_TYPE,
    };
    if (includeAuth) {
      let token = localStorage.getItem('token') ?? STATIC_TOKEN;
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
    }
    const response = await axiosInstance.post(url, requestParams, { headers });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.error || 'Something went wrong');
  }
};

export const putMedway = async (url: string, requestParams: object) => {
  try {
    const response = await axiosInstance.put(url, requestParams);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error updating data');
  }
};

export const delMedway = async (url: string, id: number) => {
  try {
    const response = await axiosInstance.delete(`${url}/${id}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Error deleting data');
  }
};
