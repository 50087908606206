import React, { useEffect, useState } from 'react';
import Image from 'next/image';

import 'react-phone-input-2/lib/style.css';

interface FloatingTextInputProps {
  label?: string;
  value?: string;
  titleActiveSize?: number;
  titleInActiveSize?: number;
  titleActiveColor?: string;
  titleInactiveColor?: string;
  placeholder?: string;
  leftButton?: boolean;
  keyboardType?: string;
  containerStyle?: React.CSSProperties;
  // eslint-disable-next-line no-unused-vars
  onChangeTextInput?: (text: string) => void;
  // eslint-disable-next-line no-unused-vars
  onFocusProp?: (focus: boolean) => void;
  maxLength?: number;
  rightButton?: boolean;
  rightButtonStyle?: React.CSSProperties;
  rightButtonImg?: string;
  rightButtonText?: string;
  rightButtonTxtStyle?: React.CSSProperties;
  rightButtonImgStyle?: React.CSSProperties;
  rightButtonDisable?: boolean;
  editable?: boolean;
  onPressProp?: () => void;
}

const FloatingTextInput: React.FC<FloatingTextInputProps> = ({
  label = '',
  value = '',
  titleActiveSize = 12,
  titleInActiveSize = 0,
  titleActiveColor = 'black',
  titleInactiveColor = 'transparent',
  placeholder = '',
  leftButton = false,
  keyboardType,
  containerStyle,
  onChangeTextInput,
  onFocusProp = () => {},
  maxLength,
  rightButton = false,
  rightButtonStyle,
  rightButtonImg,
  rightButtonText = '',
  rightButtonTxtStyle,
  rightButtonImgStyle,
  rightButtonDisable = false,
  editable = true,
  onPressProp = () => {},
}) => {
  const [text, setText] = useState<string>(value || '');
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    if (value !== text) {
      setText(value || '');
    }
  }, [value, text]);

  const handleFocus = () => {
    setFocus(true);
    onFocusProp(true);
  };

  const handleBlur = () => {
    setFocus(!!text);
    onFocusProp(false);
  };

  const handleTextChange = (inputText: string) => {
    setText(inputText);
    onChangeTextInput?.(inputText);
  };

  return (
    <div className="relative w-full" style={containerStyle}>
      {label && (
        <label
          className={`flex text-start font-medium ${
            focus || text
              ? `text-${titleActiveColor} text-${titleActiveSize}`
              : `text-${titleInactiveColor} text-${titleInActiveSize}`
          }`}
        >
          {label}
        </label>
      )}
      <div className={`mb-4 mt-2 flex ${editable ? 'bg-green' : 'bg-gray-100'}`}>
        {!leftButton && (
          <input
            type={keyboardType ?? 'text'}
            maxLength={maxLength}
            placeholder={placeholder}
            value={text}
            onChange={(e) => handleTextChange(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={!editable}
            className="w-full rounded-lg border px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-400"
          />
        )}
        {rightButton && (
          <button
            onClick={onPressProp}
            style={rightButtonStyle}
            disabled={rightButtonDisable}
            className="bg-red ml-2 rounded-lg p-2 text-white disabled:bg-gray-300"
          >
            {rightButtonText && <span style={rightButtonTxtStyle}>{rightButtonText}</span>}
            {rightButtonImg && (
              <Image src={rightButtonImg} alt="button" width={20} height={20} style={rightButtonImgStyle} />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default FloatingTextInput;
