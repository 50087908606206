import { createSlice } from '@reduxjs/toolkit';

import { logOut, resend_otp, sendOtp, UserRegisterApi, ValidateOtp } from '../thunks/authThunks';

export interface AuthState {
  token: string | null;
  userName: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  otpError: string | null;
  signupError: string | null;
  loginUpError: string | null;
  isAuthenticated: boolean | null;
}

const initialState: AuthState = {
  token: null,
  status: 'idle',
  userName: null,
  error: null,
  signupError: null,
  loginUpError: null,
  otpError: null,
  isAuthenticated: null,
};

const authSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    reset: (state, action) => {
      state.otpError = null;
      state.signupError = null;
      state.loginUpError = null;
    },
    setToken: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.token = null;
      state.userName = null;
      state.isAuthenticated = false;
      localStorage.removeItem('token');
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UserRegisterApi.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(UserRegisterApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(UserRegisterApi.rejected, (state, action) => {
        state.status = 'failed';
        state.signupError = action.payload as string;
      });

    //Otp reducer
    builder
      .addCase(sendOtp.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.status = 'failed';
        state.loginUpError = action.payload as string;
      });

    //login reducer
    builder
      .addCase(ValidateOtp.pending, (state) => {
        state.error = null;
      })
      .addCase(ValidateOtp.fulfilled, (state, action) => {
        state.token = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(ValidateOtp.rejected, (state, action) => {
        state.status = 'failed';
        state.otpError = action.payload as string;
      });

    // logout reducer
    builder
      .addCase(logOut.pending, (state) => {
        state.error = null;
      })
      .addCase(logOut.fulfilled, (state) => {
        state.token = null;
        state.isAuthenticated = false;
        localStorage.removeItem('token');
      })
      .addCase(logOut.rejected, (state, action) => {
        state.error = action.payload as string;
      });

    // resend_otp
    builder
      .addCase(resend_otp.pending, (state) => {
        state.error = null;
      })
      .addCase(resend_otp.fulfilled, (state, action) => {})
      .addCase(resend_otp.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});
export const { logout, setIsAuthenticated, setToken, reset } = authSlice.actions;
export default authSlice.reducer;
